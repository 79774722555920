// Videos
import thumbnail1 from "assets/images/1. How To Log In.jpg";
import thumbnail10 from "assets/images/10. DVIR.jpg";
import thumbnail11 from "assets/images/11. Workflow.jpg";
import thumbnail12 from "assets/images/12. Hours Of Service.jpg";
import thumbnail2 from "assets/images/2. Certify Logs.jpg";
import thumbnail3 from "assets/images/3. Pairing Truck and Tablet.jpg";
import thumbnail4 from "assets/images/4. Duty Status.jpg";
import thumbnail5 from "assets/images/5. Dot Icon.jpg";
import thumbnail6 from "assets/images/6. Notification Bell.jpg";
import thumbnail7 from "assets/images/7. MVT Mobile App.jpg";
import thumbnail8 from "assets/images/8. Form Messages.jpg";
import thumbnail9 from "assets/images/9. Messaging.jpg";
import WinterSafetyDrivingTN from "assets/images/13. Winter Safety Driving.png";
// import openEnrollment from "assets/images/open-enrollment.png";
import prepass from "assets/images/pre-pass.PNG";
import thumbnail0 from "assets/images/Tablet Anatomy.jpg";
import MVTRundown from "assets/images/mvt-rundown.png";
import difLock from "assets/images/dif-lock.png";
import thumbnailFillOutTripSheet from "assets/images/how-to-fill-out-a-trip-sheet.png";
import thumbnailNavigationSoftware from "assets/images/14 Navigation Software.png";

// Transcripts
import video2Transcript from "assets/images/transcripts/Certify Logs ENG.SPAN.png";
import video5Transcript from "assets/images/transcripts/DOT Icon ENG.SPAN.png";
import video4Transcript from "assets/images/transcripts/Duty Status ENG.SPAN.png";
import video10Transcript from "assets/images/transcripts/DVIR ENG.SPAN.png";
import video8Transcript from "assets/images/transcripts/Form Messages ENG.SPAN.png";
import video12Transcript from "assets/images/transcripts/Hours of Service ENG.SPAN.png";
import video1Transcript from "assets/images/transcripts/How to Log In ENG.SPAN.png";
import video9Transcript from "assets/images/transcripts/Messaging ENG.SPAN.png";
import video7Transcript from "assets/images/transcripts/MVT Mobile App ENG.SPAN.png";
import video6Transcript from "assets/images/transcripts/Notification Bell ENG.SPAN.png";
import video3Transcript from "assets/images/transcripts/Pairing Truck and Tablet- Bilingual 2023.jpg";
import video0Transcript from "assets/images/transcripts/Tablet Anatomy ENG.SPAN2.png";
import video11Transcript from "assets/images/transcripts/Workflow ENG.SPAN.png";
import PFSNavigationTranscript from "assets/images/transcripts/PFS  Navigation- Bilingual.jpg";

import MVTTruck from "assets/images/MVT_Truck.png";

const videos = [
	// {
	//   id: -1,
	//   title: "Important: Open Enrollment!!",
	//   thumbnailUrl: openEnrollment,
	//   videoUrl: "https://player.vimeo.com/video/763495004?h=58901f6859",
	//   videoSubtitleUrl: "",
	//   transcript: null,
	// },
	{
		id: -4,
		title: "Diff lock / Offroad Feature | International LT",
		thumbnailUrl: difLock,
		videoUrl: "https://player.vimeo.com/video/833470374?h=a776ca8812",
		videoSubtitleUrl: "",
		transcript: null,
	},
	{
		id: -3,
		title: "MVT Rundown",
		thumbnailUrl: MVTRundown,
		videoUrl: "https://player.vimeo.com/video/1017319475",
		videoSubtitleUrl: "",
		transcript: null,
	},
	{
		id: -2,
		title: "PrePass",
		thumbnailUrl: prepass,
		videoUrl: "https://player.vimeo.com/video/805210337?h=3d0a8bb7e4",
		videoSubtitleUrl: "",
		transcript: null,
	},
	{
		id: 0,
		title: "#0 Tablet Anatomy",
		thumbnailUrl: thumbnail0,
		videoUrl: "https://player.vimeo.com/video/703756801?h=b588211ea9",
		videoSubtitleUrl:
			"https://player.vimeo.com/video/711639503?h=515505c7ec",
		transcript: video0Transcript,
	},
	{
		id: 1,
		title: "#1 How To Log In",
		thumbnailUrl: thumbnail1,
		videoUrl: "https://player.vimeo.com/video/711402492?h=5167a50b53",
		videoSubtitleUrl:
			"https://player.vimeo.com/video/711641339?h=cee162b8d3",
		transcript: video1Transcript,
	},
	{
		id: 2,
		title: "#2 Certify Logs",
		thumbnailUrl: thumbnail2,
		videoUrl: "https://player.vimeo.com/video/703756400?h=d8f6ffb903",
		videoSubtitleUrl:
			"https://player.vimeo.com/video/711641866?h=82bd7760ff",
		transcript: video2Transcript,
	},
	{
		id: 3,
		title: "#3 Pairing Truck and Tablet",
		thumbnailUrl: thumbnail3,
		videoUrl: "https://player.vimeo.com/video/906146944?h=4902d7336a",
		videoSubtitleUrl:
			"https://player.vimeo.com/video/906470538?h=ac3bfb4a79",
		transcript: video3Transcript,
	},
	{
		id: 4,
		title: "#4 Duty Status",
		thumbnailUrl: thumbnail4,
		videoUrl: "https://player.vimeo.com/video/709064021?h=4d85160f7b",
		videoSubtitleUrl:
			"https://player.vimeo.com/video/713750848?h=4d062ccc87",
		transcript: video4Transcript,
	},
	{
		id: 5,
		title: "#5 Dot Icon",
		thumbnailUrl: thumbnail5,
		videoUrl: "https://player.vimeo.com/video/703756490?h=0a8c4a01dd",
		videoSubtitleUrl:
			"https://player.vimeo.com/video/711642560?h=4217e2fa9d",
		transcript: video5Transcript,
	},
	{
		id: 6,
		title: "#6 Notification Bell",
		thumbnailUrl: thumbnail6,
		videoUrl: "https://player.vimeo.com/video/703756504?h=afe102be83",
		videoSubtitleUrl:
			"https://player.vimeo.com/video/711643171?h=285405bf35",
		transcript: video6Transcript,
	},
	{
		id: 7,
		title: "#7 MVT Mobile App",
		thumbnailUrl: thumbnail7,
		videoUrl: "https://player.vimeo.com/video/703756521?h=5e6c7585a9",
		videoSubtitleUrl:
			"https://player.vimeo.com/video/713754658?h=ec023f93df",
		transcript: video7Transcript,
	},
	{
		id: 8,
		title: "#8 Form Messages",
		thumbnailUrl: thumbnail8,
		videoUrl: "https://player.vimeo.com/video/703756554?h=43da069329",
		videoSubtitleUrl:
			"https://player.vimeo.com/video/713756531?h=0061cdfb75",
		transcript: video8Transcript,
	},
	{
		id: 9,
		title: "#9 Messaging",
		thumbnailUrl: thumbnail9,
		videoUrl: "https://player.vimeo.com/video/703756582?h=8949175afe",
		videoSubtitleUrl:
			"https://player.vimeo.com/video/711645057?h=5eb6339a58",
		transcript: video9Transcript,
	},
	{
		id: 10,
		title: "#10 DVIR",
		thumbnailUrl: thumbnail10,
		videoUrl: "https://player.vimeo.com/video/703756616?h=e4657fc379",
		videoSubtitleUrl:
			"https://player.vimeo.com/video/713757939?h=44f9ad5820",
		transcript: video10Transcript,
	},
	{
		id: 11,
		title: "#11 Workflow",
		thumbnailUrl: thumbnail11,
		videoUrl: "https://player.vimeo.com/video/703756658?h=a32a390f01",
		videoSubtitleUrl:
			"https://player.vimeo.com/video/715616817?h=8decd7b58d",
		transcript: video11Transcript,
	},
	{
		id: 12,
		title: "#12 Hours Of Service",
		thumbnailUrl: thumbnail12,
		videoUrl: "https://player.vimeo.com/video/703756717?h=568536c4cf",
		videoSubtitleUrl:
			"https://player.vimeo.com/video/715611081?h=51aafd5a74",
		transcript: video12Transcript,
	},
	{
		id: 13,
		title: "#13 How To Fill Out A Trip Sheet",
		thumbnailUrl: thumbnailFillOutTripSheet,
		videoUrl: "https://player.vimeo.com/video/880170501?h=f032b9cad2",
		videoSubtitleUrl: "",
		transcript: null,
	},
	{
		id: 14,
		title: "#14 Navigation Software",
		thumbnailUrl: thumbnailNavigationSoftware,
		videoUrl: "https://player.vimeo.com/video/932554331?h=73886157b4",
		videoSubtitleUrl:
			"https://player.vimeo.com/video/939728268?h=3c1a8ef59f",
		transcript: PFSNavigationTranscript,
	},
	{
		id: 15,
		title: "Winter Safety Driving",
		thumbnailUrl: WinterSafetyDrivingTN,
		videoUrl: "https://player.vimeo.com/video/805196720?h=fa85f47d30",
		videoSubtitleUrl: "",
		transcript: null,
	},
	{
		id: 16,
		title: "How to Install a CVD/ELD Bag",
		thumbnailUrl: MVTTruck,
		videoUrl: "https://player.vimeo.com/video/906140410?h=ea0b1a17fd",
		videoSubtitleUrl: "",
		transcript: null,
	},
	{
		id: 17,
		title: "Strategies for Efficient Diesel Pumping",
		thumbnailUrl: MVTTruck,
		videoUrl: "https://player.vimeo.com/video/943856846?h=6505395b69",
		videoSubtitleUrl: "",
		transcript: null,
	},
	{
		id: 18,
		title: "APU System",
		thumbnailUrl: MVTTruck,
		videoUrl: "https://player.vimeo.com/video/984470963?h=7482df5003",
		videoSubtitleUrl: "",
		transcript: null,
	},
	{
		id: 19,
		title: "Netradyne Camera System",
		thumbnailUrl: MVTTruck,
		videoUrl: "https://player.vimeo.com/video/963669491?h=e37ece8b5d",
		videoSubtitleUrl: "",
		transcript: null,
	},
];

export default videos;
